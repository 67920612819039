<template>
<div class='page'>
     
    <div class="content2">
        <div class="platbg">
            <div class="platitem">
                <div class="platitemImg"><img :src="data.img1" alt=""></div>
                <div class="rig">
                    <div class="rigH1">{{data.H1}}</div>
                    <div class="riinfo">{{data.info}}</div>
                </div>

            </div>
             <div class="platitem2">
                 <div class="platitem2left">
                     <div class="ZoneList">
                               <div class="ZoneListitem" v-html="item.info" v-for="(item,index) in data.list" :key="index"></div>
                              
                     </div>
                 </div>
                 <div class="parig">
                     <img :src="data.img2" alt="">
                 </div>
              

            </div>
        </div>
    </div>
</div>
</template>
<script>

export default {
data() {
return {


data:{
    H1:'Our Manufacturing Plant',
    img1:require('../../assets/img/Unmbers1.png'),
    img2:require('../../assets/img/Unmbersrig1.png'),
    info:`We have two locations in China – Shenzhen and Dongguan (both close to Hong Kong). Our 12000 sq/m (130000 sq/ft) high tech manufacturing facility is split across six dedicated manufacturing zones focused on delivering impressive metal, plastic, and composite rapid parts for a wide range of industries. At HLH, we make things for you.`,
    list:[{
        info:`<span style="color:#DA251D" >Zone 1 : </span>rapid tool shop and rapid injection molding`
    },{
        info:`<span style="color:#DA251D" >Zone 2 : </span>rapid CNC milling of metals`
    },{
        info:`<span style="color:#DA251D" >Zone 3 : </span>rapid metal work and sheet metal prototyping`
    },{
        info:`<span style="color:#DA251D" >Zone 4 : </span>plastics machining and finishing + assembly`
    },{
        info:`<span style="color:#DA251D" >Zone 5 : </span>3D printing, vacuum casting, RIM`
    },{
        info:`<span style="color:#DA251D" >Zone 6 : </span>engineering and project management`
    },]
}



    
}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ }, 
components:{}
}
</script>
<style scoped>
img{
    width: 100%;
}
.parig{
      width: 60%;
    max-width: 500px;
  
}
.ZoneListitem{
    color:white;
    margin-bottom:15px ;
    font-size: 21px;
    font-weight: bold
    }
.riinfo{
    font-size: 18px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #FFFFFF;
line-height: 36px;
}
.rigH1{
    font-size: 31px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
margin-bottom:20px 
}
.platitem2{
 
       display: flex;
    justify-content: flex-end;
    
}
.platitemImg{
    max-width: 800px;
    min-width: 330px;
}
.platitem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:50px 
}
.sumData{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom:10px 
}
.rig{
    max-width:530px ;
    width: 50%;
}
.platbg{
    width: 1200px;
    margin: auto;
    min-width: 330px
}
.content2{
    background: #191919;
    width: 100%;
    padding-bottom: 80px ;
    margin-bottom: 100px 
}
.platitem2left{
    width: 54%;
}
.sum{
    color: #DA251D;
    font-weight: bold;
    font-size: 60px;
    margin-bottom:10px 

}
.info{
    font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #666666;
line-height: 24px;
}
.item{
    width: 25%;
    background: white;
    text-align: center;
    border-right:1px solid #E7E7E7 ;
    padding: 34px 15px
    
    
    }
    .item:last-child{
        border-right:none 
    }
.page{
    width: 100%;
    
}

.H1{
    font-size: 30px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
line-height: 30px;
text-align: center;
margin-bottom:30px 

}
.content{
    width: 1200px;
    margin: auto
}
 @media screen and (max-width: 760px) {
 .ZoneListitem{
     font-size: 15px
 }
.parig{
    width: 100%;
    margin: auto
}
.platitem2left{
    width: 100%;
        margin: 37px 0;
}
.platitem2{
    display: flex;
    flex-direction: column-reverse
}
.rigH1{
    font-size: 25px
}
.rig{
    width: 100%;
    margin: 30px 0;
    max-width: 100%
}
.riinfo{
    font-size: 15px;
    line-height: 28px;
}
.platbg{
    width: 90%;
    margin: auto
}
 
     .platitem{
         display: block
     }

}
@media screen and (min-width: 760px) and (max-width: 1020px) {
     
.platitemImg{
        width: 60%;
    }
    .platbg{
        width: 90%;
        margin: auto
    }
    .rigH1{
        font-size: 22px;
       
    }
    .riinfo{
     font-size: 12px;
    line-height: 21px;
    }
    .platitem2{
        justify-content: space-between
    }
    .parig{
        width: 45%;
    }
    .platitem2{
        width: 100%;
    }
    .platitem2left{
        width: 49%;
    }
    .ZoneListitem{
        font-size: 14px
    }
   .platitem{
        margin-bottom:20px 
    }


}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .platitemImg{
        width: 60%;
    }
    .platbg{
        width: 90%;
        margin: auto
    }
    .rigH1{
        font-size: 27px;
       
    }
    .riinfo{
        font-size: 15px;
        line-height: 28px;
    }
    .parig{
        width: 44%;
    }
    .platitem2{
        width: 100%;
    }
    .platitem2left{
        width: 49%;
    }
    .ZoneListitem{
        font-size: 16px
    }
    .platitem{
        margin-bottom:20px 
    }
  
}
@media screen and (min-width: 1350px) {
    
    .ZoneListitem{
        font-size: 21px
    }
}



</style>