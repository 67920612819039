<template>
<div class='page'>
    <div class="content">
            <div class="left">
                  <div class="title">{{modelabData.H1}}</div>
              

                  <div class="info" v-for="(item,index) in modelabData.dataList" :key="index">{{item.info}}</div>
                
            </div> 
         <div class="rig"><img :src="modelabData.img" alt=""></div>

    </div>
</div>
</template>
<script>
export default {
data() {
return {

}
},
methods: {},
mounted() {},
created() {},
props:{modelabData:{}},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    width: 100%;
    margin-bottom:30px 
}
img{
    width: 100%;
}
.info{
    font-size: 17px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #000000;
line-height: 33px;
margin: 10px 0;
/* margin-top:30px  */
}
.title{
    font-size: 34px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
line-height: 42px;
margin-bottom:30px 
}
.left{
    width: 42%
}
.rig{
    width: 50%;
    max-width: 600px;
    min-width: 330px
}
.content{
    width: 1200px;
    
    display: flex;
    justify-content: space-between;
    margin:  auto;
    padding-bottom: 50px
    }
 @media screen and (max-width: 760px) {

.title{
    padding: 20px 0;
    line-height: 33px;
    margin-bottom: 0px;
    font-size: 23px;
    /* text-align: center */
}
.info{
    /* text-align: center */
    font-size: 14px;
    line-height:28px ;
 
}
.left{
    width: 100%
}
.rig{
    margin: auto;
    width: 100%;
}
.content{
    width: 90%;
    min-width: 330px;
    margin: auto;
    flex-direction: column-reverse
}
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
 .title{
        font-size: 23px;
        line-height: 34px;
        margin-bottom: 17px;
    }
    .left{
        width: 44%;
    }
    .info{
        font-size: 13px;
        line-height: 25px;
    }
    .content{
        width: 90%;
    }

}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .title{
        font-size: 28px;
        line-height: 36px;
    }
    .rig{
        width: 53%;
    }
    .info{
        font-size: 14px;
        line-height: 27px;
    }
    .content{
        width: 90%;
    }

}
@media screen and (min-width: 1350px) {
    
}

</style>