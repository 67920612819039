<template>
<div class='page'>
 <div class="banner">
            <div class="w">
                <div class="info">
                    <div class="title">About us</div>
                    <p class="title1">With 20 years of CNC Machining we have helped our customers to complete thousands products design and manufacturing</p>
                </div>
            </div>
        </div>
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
  <modelab :modelabData="modelabData"></modelab>
  <by :byData="byData"></by>

  <unmbers ></unmbers>
    <modelab :modelabData="modelabData2"></modelab>
  
  
</div>
</template>
<script> 
import myhead from '../../components/myHead.vue'
import modelab from './modelab.vue'
import unmbers from './Unmbers.vue'
import by from '../../components/by.vue'

import bread from '../../components/bread.vue'
export default {
data() {
return {
        hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
    byData:{
        H1:'BY THE NUMBERS',
        bg:'#ffffff',
        color:'#000000',
        lists:[{
            img:require('../../assets/img/sr1.png'),
            info:'12,000 sq/m Manufacturing Facility',
        },{
             img:require('../../assets/img/sr3.png'),
            info:'Experienced Engineers',
        },{
            img:require('../../assets/img/sr4.png'),
            info:'Up-to-date Machines',
        },{
             img:require('../../assets/img/sr2.png'),
            info:'Clients — Lasting relationships， from Fortune 100 to startups',
        },],
    },
        modelabData:{
        H1:'PROVIDING CNC MACHINING SERVICES FOR MORE THAN 20 YEARS',
        img:require('../../assets/img/modelab01.png'),
        dataList:[{
            info:'HLH is a leader in CNC Machining. ',
        },{
            info:'With its focus on providing its customers with high quality and cost-effective manufacturing,HLH pioneers solutions for every industry from automotive, medical, communications, aviation and more. ',
        },{
            info:' With 20 years of CNC Machining we have helped our customers to complete thousands products design and manufacturing.',
        },]
    },
      modelabData2:{
        H1:'DELIVERING WHAT IS MOST IMPORTANT – THE END RESULT',
          img:require('../../assets/img/modelab02.png'),
        dataList:[{
            info:'We are more than 100 talented engineers, designers, specialists and experts in the fields of mechanics, electronics, software and hardware. ',
        },{
            info:'Through a collaborative approach, we provide high quality design and manufacturing services to support our customers in minimizing cost, optimizing the design, improving performances and achieving the goals.',
        }]
    }
}
},
methods: {},
mounted() {},
created() {},
props:{}, 
watch:{ },
components:{
  myhead,
  modelab,
  unmbers,
  by,
  bread,
}
}
</script>
<style scoped lang="scss">
.page{
  
}
.banner{
        background: url('~@/assets/img/aboutUsbanner.png') no-repeat;
        
  
        width: 100%;
        // height: 560px;
        transition: 0.25s;
    
        padding-top: 70px;
        .w{
         
            display: flex;
            margin: auto;
            // justify-content: flex-end;
            margin-top: 13px;
        }
        .btn {
            display: inline-block;
             padding: 10px 15px;
          
            cursor: pointer;
            border: 1px solid #071930;
            // font-size: 16px;
            text-align: center;

            color: #071930;
            // margin-left:64px ;
        }
        .title1{
            // font-size: 60px;
            margin-bottom: 2vw;
            transition: 0.25s;
            color: #ffffff;
          
      
        }
        .info {
      
    
            font-size: 24px;
            color: #333333;
            // margin-top: 30px;    
            .item{
                display: flex;
                align-items: center;
            
            }
            >p {
                // margin-bottom: 30px;
                img {
                    vertical-align: middle;
                    margin-right: 20px;
                    // width: 36px;
                    // height: 36px;
                    max-width: 36px;
                    min-width: 25px;
                 
                }
                span {
                    vertical-align: middle;
                }
            }
            >.title {
                // font-size: 48px;
                // margin-bottom: 0;
                color: #ffffff;
           transition: 0.25s;
            }
        }
    }
    
@media screen and (max-width: 760px) {   //最小  min-width="330px"
    .info{
    //   position: absolute;
    // right: 2vw;
    // top: 167px;
    width: 62vw;
    min-width: 220px;
    }
    .w{
        width: 90%;
        min-width: 330px;
        margin-top:80px !important 
    }
    .title{
           font-size: 40px;
    font-weight: bold;
    margin-bottom: 2vw;
    } 
    .title1{
     font-size: 16px;
    // font-weight: bold;
    line-height: 25px;
    // width: 42vw;
          // line-height: 25px;
    }
    img{
        width: 26px
    }
    .itemT{
        font-size: 14px;
    }
    p{
        margin-bottom:10px ;
    }
    .banner{
        height: 500px;
        background-position:50% 90% !important;
    }
     .btn{
        font-size: 14px;
        margin-left: 48px;
    }
}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {

    .w{
        width: 90%;
    }
    .title{
        font-size: 60px;
        margin-bottom: 54px;
    }
    .title1{
        font-size: 18px;
    line-height: 28px;
    // width: 41vw;
    }
    img{
        width: 30px;
    }
    .itemT{
        font-size: 22px;
    }
    p{
        margin-bottom:30px ;
    }
    .banner{
        height: 520px;
        background-position: center !important;
    }
    .btn{
        margin-left: 64px;
        font-size: 16px;
    }
    .info{
        margin-top: 30px;
        width: 46vw;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .w{
        width: 90%;
    }
    .title{
        font-size: 80px;
        margin-bottom: 54px;
    }
   .title1{
       font-size: 19px;
    line-height: 32px;
    }
    img{
        width: 36px;}
        .itemT{
            font-size: 24px;
        }  p{
            margin-bottom:30px ;
        }
        .banner{
            height: 520px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
                  width: 36vw;
        }
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
    .w{
        width: 1200px;
    }
    .title{
        font-size: 100px;
        margin-bottom: 54px;
    }
    .title1{
        font-size: 24px;
    // width: 478px;
    line-height: 36px;
    }
    .itemT{
        font-size: 24px;
    }
    img{
        width: 36px;}
        p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
            width: 620px;
        }
}
</style>