<template>
<div class='page' :style="{background:byData.bg}">
    <div class="content">
        <div class="H1">{{byData.H1}}</div>
        <div class="sumData">
            <div class="item" v-for="(item,index) in byData.lists" :key="index">
                <div :class="'sum sum'+(index+1)"><img :src="item.img" alt=""></div>
                <div class="info" :style="{color:byData.color}">{{item.info}}</div>
            </div>
             

        </div>

     
    </div>
</div>
</template>
<script>
export default {
data() {
return {
    
}
},
methods: {},
mounted() {},
created() {},
props:{byData:{}},
watch:{ },
components:{}
}
</script>
<style scoped>
img{
    width: 100%;
}



.platitem2{
 
 
       display: flex;
    justify-content: flex-end;
    
}
.platitemImg{
    max-width: 800px;
    min-width: 330px;
}
.platitem{
    display: flex;
    justify-content: space-between;
    align-items: center
}
.sumData{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom:10px 
}
.rig{
    max-width:530px ;
    width: 50%;
}

 

 
.item{
    width: 25%;
    background: white;
    text-align: center;
    border-right:1px solid #E7E7E7 ;
    padding: 34px 15px
    
    
    }
    .item:last-child{
        border-right:none 
    }
.page{
    width: 100%;
    
}


img{
    width: 100%;
}
.H1{
    font-size: 30px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
line-height: 30px;
text-align: center;
margin-bottom:30px 

}
.content{
    width: 1200px;
    margin: auto;
     
}
.sum{
    color: #DA251D;
    font-weight: bold;
     width:250px ;
    /* font-size: 60px; */
    margin: auto;
    margin-bottom:20px 

}
.sum1{
    max-width:244px ;
}
.sum2{
    max-width:164px ;
}
.sum3{
  max-width:164px ;
 
}
.sum4{
  max-width:192px ;
 
}

 @media screen and (max-width: 760px) {
     .H1{
         font-size: 20px
     }
  .content{
        width: 90%;
        min-width: 330px
    }
  

    .info{
        font-size: 12px
    }
    .item{
        width: 50%;
        border-right:none ;
        border-left:1px solid  #E7E7E7;
         border-bottom:1px solid  #E7E7E7
    }
    .item:nth-child(1){
         border-left:none;

    }.item:nth-child(3){
         border-left:none;
           border-bottom:none

    }.item:nth-child(4){
    
           border-bottom:none

    }

.sum1{
    max-width: 64%;
}
.sum2{
    max-width:52% ;
}
.sum3{
  max-width:52% ;
 
}
.sum4{
  max-width:56% ;
 
}

}
@media screen and (min-width: 760px) and (max-width: 1020px) {
      .content{
        width: 90%
    }
    
    .info{
        font-size: 14px
    }
    .item{
        width: 50%;
        border-right:none ;
        border-left:1px solid  #E7E7E7;
         border-bottom:1px solid  #E7E7E7
    }
    .item:nth-child(1){
         border-left:none;

    }.item:nth-child(3){
         border-left:none;
           border-bottom:none

    }.item:nth-child(4){
    
           border-bottom:none

    }
    .platitemImg{
        width: 56%;
    }
.content{
        width: 90%
    }
     
    .info{
        font-size: 14px
    }


.sum1{
    max-width: 64%;
}
.sum2{
    max-width:52% ;
}
.sum3{
  max-width:52% ;
 
}
.sum4{
  max-width:56% ;
 
}

 


}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%
    }
   
    .info{
        font-size: 14px
    }
  
.sum1{
max-width: 87%;
}
.sum2{
    max-width:64% ;
}
.sum3{
  max-width:64% ;
 
}
.sum4{
  width:75% ;
 
}

}
@media screen and (min-width: 1350px) {
    
}

</style>